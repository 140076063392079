import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useUtilState } from '../provider/UtilProvider';
import './Carousel.scss';
import factory1 from '../img/factory/factory1.jpg';
import factory2 from '../img/factory/factory2.jpg';
import factory3 from '../img/factory/factory3.jpg';
import factory4 from '../img/factory/factory4.jpg';

export default function FactoryCarouselComponent() {
  const { isSmallScreen } = useUtilState();

  return (
    <div className="factory-and-warehouse">
      {
        !isSmallScreen && (
        <Carousel
          controls={false}
        >
          <Carousel.Item activeIndex={1} interval={3000}>
            <div
              className="d-block w-100"
              style={{
                height: 700,
                background: `url(${factory1}) no-repeat center / cover`,
                color: 'white',
              }}
            />
          </Carousel.Item>
          <Carousel.Item activeIndex={2} interval={3000}>
            <div
              className="d-block w-100"
              style={{
                height: 700,
                background: `url(${factory2}) no-repeat center / cover`,
                color: 'white',
              }}
            />
          </Carousel.Item>
          <Carousel.Item activeIndex={3} interval={3000}>
            <div
              className="d-block w-100"
              style={{
                width: '60%',
                height: 700,
                background: `url(${factory3}) no-repeat center / cover`,
                color: 'white',
              }}
            />
          </Carousel.Item>
          <Carousel.Item activeIndex={4} interval={3000}>

            <div
              className="d-block w-100"
              style={{
                height: 700,
                background: `url(${factory4}) no-repeat center / cover`,
                color: 'white',
              }}
            />
          </Carousel.Item>
        </Carousel>
        )
      }

      <div className="circle-container-1">
        <div className="circle">
          <div className="text">
            <h1>
              FACTORY &
              {' '}
              <br />
              {' '}
              WAREHOUSE
            </h1>
            {!isSmallScreen && (
              <p>
                Our factory and warehouse are located in Tasek Industrial
                Area, Ipoh, which is the site of many limestone quarries.
                The plant is equipped with 6 production lines and an annual
                production capacity of over 70,000 tonnes. Our production
                plant is certified to ISO standard with an emphasis on
                quality to meet the demands of a wide range of industries.
              </p>
            )}
          </div>
        </div>
      </div>

      {isSmallScreen && (
        <p>
          Our factory and warehouse are located in Tasek Industrial Area,
          Ipoh, which is the site of many limestone quarries. The plant is
          equipped with 6 production lines and an annual production capacity
          of over 70,000 tonnes. Our production plant is certified to ISO
          standard with an emphasis on quality to meet the demands of a wide
          range of industries.
        </p>
      )}
    </div>
  );
}
