import React, { useEffect, useState, useMemo } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { smoothScrollTo, useUtilState } from '../provider/UtilProvider';
import logo from '../img/logo/logo.png';
import logoTransparent from '../img/logo/logo-transparent.png';
import homeIconRed from '../img/icon/home_red.png';
import homeIconBlue from '../img/icon/home_blue.png';

export default function Header() {
  const {
    isSmallScreen, setCurrentSelectedLink, currentSelectedLink,
    prevSelectedLink, setPrevSelectedLink,
  } = useUtilState();
  const [currentScrollState, setCurrentScrollState] = useState(true);
  const ourCompanyRef = React.createRef();
  const ourProductsRef = React.createRef();
  const ourFacilitiesRef = React.createRef();
  const contactUsRef = React.createRef();
  const homeRef = React.createRef();
  const [homeIcon, SetHomeIcon] = useState(homeIconRed);
  const [disableSelect, setDisableSelect] = useState(false);
  const [scrollListener, setScrollListener] = useState();
  const renderSmallScreenHeader = () => (isSmallScreen
    ? {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
    : {});

  const headerOnHover = (action, link) => {
    if (action === 'enter') {
      if (prevSelectedLink.length > 50) {
        setPrevSelectedLink([prevSelectedLink[0]]);
      }
      setCurrentSelectedLink(link);
    } else if (prevSelectedLink.length > 0) {
      setCurrentSelectedLink(prevSelectedLink[0]);
    }
  };
  const debouncedSave = (value) => {
    setCurrentSelectedLink(value);
    setPrevSelectedLink([value]);
  };

  useEffect(() => {
    const home = document.querySelector('#home');
    const ourCompany = document.querySelector('#our-company');
    const ourProducts = document.querySelector('#our-products');
    const ourFacilities = document.querySelector('#our-facilities');
    const contactUs = document.querySelector('#contact-us');
    window.addEventListener('scroll', (e) => {
      setTimeout(() => {
        const sectionList = [home, ourCompany, ourProducts, ourFacilities, contactUs];
        let current = 'home';
        sectionList.forEach((section) => {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.clientHeight;
          if (window.scrollY < 200) {
            current = 'home';
          } else if (window.scrollY + window.innerHeight >= document.body.scrollHeight) {
            current = 'contact-us';
          } else if (window.scrollY >= sectionTop - sectionHeight) {
            current = section.id;
          }
        });

        if (currentScrollState) {
          setCurrentScrollState(false);
          setTimeout(() => {
            setCurrentScrollState(true);
            if (current === 'home') {
              SetHomeIcon(homeIconRed);
            } else {
              SetHomeIcon(homeIconBlue);
            }
            debouncedSave(current);
          }, 100);
        }
      }, 800);
    });
  }, []);
  //       if (link === 'home') { SetHomeIcon(homeIconRed); } else { SetHomeIcon(homeIconBlue); }

  return (
    <header style={renderSmallScreenHeader()}>
      {!isSmallScreen ? (

        <nav className="blue-color font-bold">
          <div
            className="header-logo-container"
            onClick={() => {
              smoothScrollTo('#home');
              setCurrentSelectedLink('home');
              setPrevSelectedLink(['home']);
              SetHomeIcon(homeIconRed);
            }}
            role="button"
            tabIndex={0}
          >
            <span>
              <img src={logo} alt="logo" className="logo" />
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '70%' }}>
            <div
              ref={ourCompanyRef}
              onClick={() => {
                smoothScrollTo('#our-company');
                setTimeout(() => {
                  setCurrentSelectedLink('our-company');
                  setPrevSelectedLink(['our-company']);
                  SetHomeIcon(homeIconBlue);
                }, 2000);
              }}
              className="nav-item"
              onMouseEnter={() => headerOnHover('enter', 'our-company')}
              onMouseLeave={() => headerOnHover('leave', 'our-company')}
              role="button"
              tabIndex={0}
            >
              <div
                className={`${currentSelectedLink === 'our-company' ? 'selected' : ''} ${prevSelectedLink[0] === 'our-company' ? 'selected-origin' : ''}`}
              >
                <span>OUR COMPANY</span>
              </div>
            </div>
            <div
              ref={ourProductsRef}
              role="button"
              onClick={() => {
                setCurrentSelectedLink('our-products');
                smoothScrollTo('#our-products');
                setPrevSelectedLink(['our-products']);
                SetHomeIcon(homeIconBlue);
              }}
              className="nav-item"
              onMouseEnter={() => headerOnHover('enter', 'our-products')}
              onMouseLeave={() => headerOnHover('leave', 'our-products')}
              tabIndex={0}
            >
              <div
                className={`${currentSelectedLink === 'our-products' ? 'selected' : ''} ${prevSelectedLink[0] === 'our-products' ? 'selected-origin' : ''}`}
              >
                <span>OUR PRODUCTS</span>
              </div>
            </div>
            <div
              role="button"
              ref={ourFacilitiesRef}
              onClick={() => {
                setCurrentSelectedLink('our-facilities');
                smoothScrollTo('#our-facilities');
                setPrevSelectedLink(['our-facilities']);
                SetHomeIcon(homeIconBlue);
              }}
              className="nav-item"
              onMouseEnter={() => headerOnHover('enter', 'our-facilities')}
              onMouseLeave={() => headerOnHover('leave', 'our-facilities')}
              tabIndex={0}
            >
              <div
                className={`${currentSelectedLink === 'our-facilities' ? 'selected' : ''} ${prevSelectedLink[0] === 'our-facilities' ? 'selected-origin' : ''}`}
              >
                <span>OUR FACILITIES</span>
              </div>
            </div>
            <div
              role="button"
              ref={contactUsRef}
              onClick={() => {
                setCurrentSelectedLink('contact-us');
                smoothScrollTo('#contact-us');
                setPrevSelectedLink(['contact-us']);
                SetHomeIcon(homeIconBlue);
              }}
              className="nav-item"
              onMouseEnter={() => headerOnHover('enter', 'contact-us')}
              onMouseLeave={() => headerOnHover('leave', 'contact-us')}
              tabIndex={0}
            >
              <div
                className={`${currentSelectedLink === 'contact-us' ? 'selected' : ''} 
                ${prevSelectedLink[0] === 'contact-us' ? 'selected-origin' : ''}`}
              >
                <span>CONTACT US</span>
              </div>
            </div>
            <div
              role="button"
              ref={homeRef}
              onClick={() => {
                setCurrentSelectedLink('home');
                smoothScrollTo('#home');
                setPrevSelectedLink(['home']);
                SetHomeIcon(homeIconRed);
              }}
              className="nav-item"
              onMouseEnter={() => headerOnHover('enter', 'home')}
              onMouseLeave={() => headerOnHover('leave', 'home')}
              tabIndex={0}
            >
              <div
                className={`${currentSelectedLink === 'home' ? 'selected' : ''} ${prevSelectedLink[0] === 'home' ? 'selected-origin' : ''}`}
              >
                <span>HOME</span>
                <img
                  src={homeIcon}
                  alt="home"
                  style={{ width: 18, marginLeft: 5 }}
                />
              </div>
            </div>
          </div>
        </nav>

      ) : (
        <nav
          className="blue-color small-nav font-bold"
        >
          <div className="header-logo-container">
            <a href="#home">
              <img src={logo} alt="logo" className="logo" style={{ height: 25 }} />
            </a>
          </div>
          <NavDropdown title="" id="basic-nav-dropdown">
            <NavDropdown.Item
              href="#our-company"
              className="title-color font-bold"
            >
              OUR COMPANY
            </NavDropdown.Item>
            <NavDropdown.Item
              href="#our-products"
              className="title-color font-bold"
            >
              OUR PRODUCTS
            </NavDropdown.Item>
            <NavDropdown.Item
              href="#our-facilities"
              className="title-color font-bold"
            >
              OUR FACILITIES
            </NavDropdown.Item>
            <NavDropdown.Item
              href="#contact-us"
              className="title-color font-bold"
            >
              CONTACT US
            </NavDropdown.Item>
            <NavDropdown.Item
              href="#home"
              className="title-color font-bold"
            >
              HOME
            </NavDropdown.Item>
          </NavDropdown>
        </nav>
      )}
    </header>
  );
}
