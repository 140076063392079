import React from 'react';
import './Footer.scss';
import logoTransparent2 from '../img/logo/logo.svg';
import { smoothScrollTo, useUtilState } from '../provider/UtilProvider';

export default function Footer() {
  const { setCurrentSelectedLink, setPrevSelectedLink } = useUtilState();
  const goToLink = (link) => {
    setCurrentSelectedLink(link);
    smoothScrollTo(`#${link}`);
    setPrevSelectedLink([link]);
  };
  return (
    <footer>
      <img src={logoTransparent2} alt="logo" />
      <div>
        <button className="border-right" type="button" onClick={() => goToLink('our-company')}>
          OUR COMPANY
        </button>
        <button className="border-right" type="button" onClick={() => goToLink('our-products')}>
          OUR PRODUCTS
        </button>
        <button className="border-right" type="button" onClick={() => goToLink('our-facilities')}>
          OUR FACILITIES
        </button>
        <button className="border-right" type="button" onClick={() => goToLink('contact-us')}>
          CONTACT US
        </button>
        <button type="button" onClick={() => goToLink('home')}>
          HOME
        </button>

      </div>
      <div>
        <span>Copyright ©</span>
        {' '}
        <span style={{ fontWeight: '800' }}>Calrock Sdn. Bhd.</span>
        {' '}
        <span>All Rights Reserved.</span>
      </div>
    </footer>
  );
}
