import React, { useState, useEffect } from 'react';
import './Form.scss';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useUtilState } from '../provider/UtilProvider';
import code1 from '../img/codes/1045.jpg';
import code2 from '../img/codes/2637.jpg';
import code3 from '../img/codes/3687.jpg';
import code4 from '../img/codes/5639.jpg';

export default function FormComponent() {
  const [fullName, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [message, setMessage] = useState('');
  const [selectedProduct, setSelectedProducts] = useState([]);
  const [captcha, setCaptcha] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [captchaIndex, setCaptchaIndex] = useState();
  const products = ['CR-1', 'CR-2', 'CR-4', 'CR325', 'CR200'];
  const { isSmallScreen } = useUtilState();
  const [show, setShow] = useState(false);

  const captchaValue = [{
    img: code1,
    value: '1045',
  }, {
    img: code2,
    value: '2637',
  }, {
    img: code3,
    value: '3687',
  }, {
    img: code4,
    value: '5639',
  }];

  const handleSubmit = (e) => {
    if (captcha === captchaValue[captchaIndex].value) {
      e.preventDefault();
      setCaptchaError('');
      const data = {
        fullname: fullName,
        email,
        companyname: companyName,
        contactnumber: contactNumber,
        products: selectedProduct.join(),
        message,
        // captcha,
      };
      console.log(data);
      fetch('/v2/php/email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((res) => {
        setShow(true);
        console.log(res);
      })
        .catch((err) => console.log(err));
    } else {
      e.preventDefault();
      setCaptchaError('Incorrect captcha input');
    }
  };
  const handleCheckBox = (e) => {
    console.log(e);
    if (e.checked) {
      setSelectedProducts((arr) => [...arr, e.value]);
    } else {
      setSelectedProducts(selectedProduct.filter((p) => p !== e.value));
    }
    console.log(selectedProduct);
  };

  const randomImage = () => {
    const index = Math.floor(Math.random() * captchaValue.length);
    setCaptchaIndex(index);
  };

  useEffect(() => {
    const index = Math.floor(Math.random() * captchaValue.length);
    setCaptchaIndex(index);
  }, []);

  if (show) {
    return (
      <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Form submitted successfully</Alert.Heading>
      </Alert>
    );
  }
  return (
    <div>
      {!isSmallScreen && (
      <Form className="form" onSubmit={(e) => handleSubmit(e)}>
        <div className="mb-2 black-color opacity-50" style={{ fontSize: '0.8rem', fontWeight: '400' }}>
          All fields marked with * are required.
        </div>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label>Full Name*</Form.Label>
            <Form.Control onChange={(e) => setFullname(e.target.value)} required type="text" />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCompany">
            <Form.Label>Company Name*</Form.Label>
            <Form.Control onChange={(e) => setCompanyName(e.target.value)} required type="text" />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridContact">
            <Form.Label>Contact Number*</Form.Label>
            <Form.Control onChange={(e) => setContactNumber(e.target.value)} required type="text" />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email Address*</Form.Label>
            <Form.Control onChange={(e) => setEmail(e.target.value)} required type="email" />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridProduct">
            <Form.Label>Product</Form.Label>
            {products.map((product) => (
              <div key={product}>
                <Form.Check
                  label={product}
                  name="group1"
                  type="checkbox"
                  value={product}
                  onChange={(e) => handleCheckBox(e.target)}
                />
              </div>
            ))}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridMessage">
            <Form.Label>Message*</Form.Label>
            <Form.Control onChange={(event) => setMessage(event.target.value)} required as="textarea" rows={3} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md={6} controlId="formGridCaptcha">
            <Form.Label style={{ fontSize: '0.8rem' }}>
              <div>
                <img src={captchaIndex ? captchaValue[captchaIndex].img : captchaValue[0].img} alt="captcha" />
                <div>What code is in the image?*</div>
              </div>
            </Form.Label>
            <Col>
              <Form.Control onChange={(event) => setCaptcha(event.target.value)} required type="text" isInvalid={captchaError} />
              <Form.Control.Feedback type="invalid">
                {captchaError}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Row>
        <Button
          type="submit"
          className="action-button"
        >
          Submit

        </Button>
      </Form>
      )}
      {isSmallScreen && (
        <Form className="form" onSubmit={handleSubmit}>
          {selectedProduct}
          <div className="mb-2 black-color opacity-50" style={{ fontSize: '0.8rem', fontWeight: '400' }}>
            All fields marked with * are required.
          </div>
          <Row>
            <Form.Group className="mb-3" as={Col} controlId="formGridName">
              <Form.Control placeholder="FULL NAME*" onChange={(e) => setFullname(e.target.value)} required type="text" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" as={Col} controlId="formGridCompany">
              <Form.Control placeholder="COMPANY NAME*" onChange={(e) => setCompanyName(e.target.value)} required type="text" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" as={Col} controlId="formGridContact">
              <Form.Control placeholder="CONTACT NUMBER*" onChange={(e) => setContactNumber(e.target.value)} required type="text" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" as={Col} controlId="formGridEmail">
              <Form.Control placeholder="EMAIL*" onChange={(e) => setEmail(e.target.value)} required type="email" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" as={Col} controlId="formGridProduct">
              <div><Form.Label>Product</Form.Label></div>
              {products.map((product) => (
                <Form.Check
                  inline
                  label={product}
                  name="group1"
                  type="checkbox"
                  value={product}
                  onChange={(e) => handleCheckBox(e.target)}
                />
              ))}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="formGridMessage">
              <Form.Control placeholder="MESSAGE*" onChange={(event) => setMessage(event.target.value)} required as="textarea" rows={3} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="formGridCaptcha">
              <Form.Label style={{ fontSize: '0.8rem' }}>
                <img src={captchaIndex ? captchaValue[captchaIndex].img : captchaValue[0].img} alt="captcha" />
              </Form.Label>
              <Form.Control
                placeholder="What code is in the image?*"
                onChange={(event) => setCaptcha(event.target.value)}
                required
                type="text"
              />
            </Form.Group>
          </Row>
          <Button
            type="submit"
            className="action-button"
            onClick={(e) => handleSubmit()}
          >
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
}
