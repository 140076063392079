import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const UtilContext = React.createContext({
  isSmallScreen: false,
  setIsSmallScreen: () => {
    throw new Error('method not implemented');
  },
  currentSelectedLink: 'home',
  setCurrentSelectedLink: () => {
    throw new Error('method not implemented');
  },
  prevSelectedLink: 'home',
  setPrevSelectedLink: () => {
    throw new Error('method not implemented');
  },

});

function UtilProvider({ children }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [currentSelectedLink, setCurrentSelectedLink] = useState('home');
  const [prevSelectedLink, setPrevSelectedLink] = useState('home');

  const setScreenSize = () => {
    if (window.innerWidth <= 920) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    setCurrentSelectedLink('home');
    setScreenSize();
  }, []);

  window.addEventListener('resize', () => {
    setScreenSize();
  });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UtilContext.Provider value={{
      isSmallScreen,
      setIsSmallScreen,
      currentSelectedLink,
      setCurrentSelectedLink,
      prevSelectedLink,
      setPrevSelectedLink,
    }}
    >
      {children}
    </UtilContext.Provider>
  );
}
UtilProvider.propTypes = {
  children: PropTypes.arrayOf.name.isRequired,
};

export const useUtilState = () => useContext(UtilContext);

export const smoothScrollTo = (id) => {
  const element = document.querySelector(id);
  element.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
};

export default UtilProvider;
