import './App.css';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import CoreValueCarousel from './component/CoreValueCarousel';
import ApplicationAccordion from './component/ApplicationAccordion';
import FactoryCarousel from './component/FactoryCarousel';
import CalrockForm from './component/CalrockForm';
import logoTransparent from './img/logo/logo-transparent.png';
import companyCircle1 from './img/our-company/circle1.jpg';
import productCircle1 from './img/our-products/circle1.jpg';
// import chineseLogo from './img/logo/chinese-logo.png';

import openQuote from './img/our-company/open-quote.png';
import closeQuote from './img/our-company/close-quote.png';
import Header from './component/Header';
import { useUtilState } from './provider/UtilProvider';
import circle1 from './img/Home/circle1.jpg';
import circle2 from './img/Home/circle2.jpg';
import circle3 from './img/Home/circle3.jpg';
import circle4 from './img/Home/circle4.jpg';
import Footer from './component/Footer';

function App() {
  const { isSmallScreen } = useUtilState();
  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  return (
    <div className="app-container">
      <Header />
      <main>
        <div className="landing" id="home">
          <div className="main-logo">
            <img src={logoTransparent} alt="logo" id="normal-logo" />
          </div>
        </div>
        {!isSmallScreen && (
          <div className="circle-outer-container">
            <div className="circle-container">
              <a href="#our-company">
                <div>
                  <img id="our-company-circle" alt="circle" src={circle1} />
                  <span>OUR COMPANY</span>
                </div>

              </a>
              <a href="#our-products">
                <div id="our-products-circle">
                  <img id="our-company-circle" alt="circle" src={circle2} />
                  <span>OUR PRODUCTS</span>

                </div>
              </a>
              <a href="#our-facilities">
                <div id="our-facilities-circle">
                  <img id="our-company-circle" alt="circle" src={circle3} />
                  <span>OUR FACILITIES</span>

                </div>
              </a>
              <a href="#contact-us">
                <div id="contact-us-circle">
                  <img id="our-company-circle" alt="circle" src={circle4} />
                  <span>CONTACT US</span>

                </div>
              </a>
            </div>
          </div>
        )}
        <div className="our-company" id="our-company">
          <h2 className="white-color" style={{ marginBottom: 72 }}>OUR COMPANY</h2>
          <h1 className="blue-color">
            <div className="quotation-mark mt2r" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
              <img src={openQuote} alt="open-quote" />
            </div>
            <span className="title-color font-bold">Calrock Sdn Bhd, </span>
            the pioneer manufacturer of calcium carbonate in Malaysia,
            was incorporated in 1970. The company commenced production on 1st
            May 1971. We started out with only one production
            line and we have grown by leaps and bounds since then.
            <div className="quotation-mark mt2r" style={{ marginTop: '1rem', marginBottom: '4rem' }}>
              <img src={closeQuote} alt="open-quote" />
            </div>
          </h1>
          <div className="introduction">
            <div>
              <p className="blue-color font-bold">
                Zantat&lsquo;s management team took the reins of Calrock in May 2000.
                Calrock has since became a sister company of Zantat Sdn Bhd.
                In 2021, Calrock Sdn Bhd has become a 100% owned subsidiary of Zantat Sdn Bhd.
              </p>
              <p>
                A new era began for the company as we expanded to 6 production
                lines with an annual production capacity of 70,000 tonnes. More
                new grades have been added into our product portfolio. Today, we
                are supplying our products to serve various industries such as Paints, Plastics, Rubber,
                Adhesives, PVC, Plaster and etc. 
              </p>
            </div>
            <div>
              <p>
                With over 30 years of experience in the manufacturing of calcium
                carbonate, we understand the challenges in this industry. We
                meet these challenges with our ongoing commitment to strict
                quality assurance and on time delivery.
              </p>
              <p>
                We will continuously improve production efficiency and maintain
                our products quality that could enable us to achieve
                ongoing growth with our customers.
              </p>
            </div>
          </div>
        </div>
        <div className="company-mission company-mission-1">
          <div className="circle">
            <img src={companyCircle1} alt="" style={{ position: 'absolute', zIndex: 0 }} />
            <h2 style={{ textAlign: 'end', zIndex: 1 }}>
              OUR
              <br />
              COMPANY
              <br />
              MISSION
            </h2>
          </div>
          <div className="description">
            <h2 className="title-color">
              Pursuit of
              <br />
              <span className="blue-color">
                <span>Quality </span>
                <span style={{ fontWeight: '500' }}>&amp;</span>
                <span> Consistency.</span>
              </span>
            </h2>
            <p>
              Calrock&lsquo;s mission is to create and supply high-quality and
              cost-effective calcium carbonate products. It is our commitment to
              meet stringent & consistent quality standards. We strive to become
              a leading calcium carbonate manufacturer in the Asian region and
              beyond.
            </p>
          </div>
        </div>
        <CoreValueCarousel />
        <div
          className="company-mission our-product-background"
          id="our-products"
        >
          <div className="circle">
            <img src={productCircle1} alt="" />
          </div>
          <div className="title-color">
            { !isSmallScreen && (
            <h2 style={{ lineHeight: 0.9 }}>
              OUR
              {' '}
              <br />
              PRODUCTS
            </h2>
            )}
            <p className="font-ultra-bold sub-desc no-margin">
              Uncoated Calcium Carbonate Powder
            </p>

            <p className="black-color common-desc">
              Our uncoated calcium carbonate powder is produced from high grade
              limestone. Various particles sizes ranging from 325 mesh to 3000
              mesh are available for a wide range of applications including
              Plastics, Rubber, Paints & Coatings and etc.
            </p>
            <button type="button" className="action-button" onClick={modalShow}>ASK A QUESTION</button>
          </div>
        </div>
        <div className="applications">
          <ApplicationAccordion />
        </div>
        <div id="our-facilities">
          <FactoryCarousel />

        </div>
        <div className="contact">
          {!isSmallScreen && (
            <div style={{ flex: 1 }}>
              <h2 className="title-color">OUR LOCATION</h2>

              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    style={{ width: '100%' }}
                    height="230"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=CALROCK%20%20S&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    title="google-map"
                  />

                  <br />
                </div>
              </div>
            </div>
          )}
          <div id="contact-us" style={{ flex: 1 }}>
            <h2 className="title-color">CONTACT DETAILS</h2>
            {isSmallScreen && <h2 className="title-color mt-2" style={{ fontSize: '1rem' }}>ADD</h2>}
            {!isSmallScreen && (
              <div>
                <p>
                  60, Persiaran Portland, Tasek Industrial Estate, 31400 Ipoh, Perak
                </p>
                <div className="contact-details">
                  <div style={{ color: '#b2202e', fontWeight: '800' }}>TEL</div>
                  <div>+605-2911 444</div>
                  <div />
                  <div>+605-2915 768</div>
                  <div style={{ color: '#b2202e', fontWeight: '800' }}>FAX</div>
                  <div>+605-2911 106</div>
                  <div style={{ color: '#b2202e', fontWeight: '800' }}>EMAIL</div>
                  <div>calrock@calrock.com.my</div>
                </div>
              </div>
            )}
            {isSmallScreen && (
              <div>
                <p style={{ textAlign: 'center' }}>
                  60, Persiaran Portland, Tasek Industrial Estate, 31400 Ipoh, Perak
                </p>
                <div>
                  <div style={{ marginBottom: '0', color: '#b2202e', fontWeight: '800' }}>TEL</div>
                  <div>+605-2911 444 / +605-2915 768</div>
                  <div style={{ marginBottom: '0', color: '#b2202e', fontWeight: '800' }}>FAX</div>
                  <div>+605-2911 106</div>
                  <div style={{ marginBottom: '0', color: '#b2202e', fontWeight: '800' }}>EMAIL</div>
                  <div>calrock@calrock.com.my</div>
                </div>
                <div style={{ margin: '36px 0' }}>
                  <a className="action-button" href="https://www.google.com/maps/place/Calrock+Sdn.+Bhd./@4.6367609,101.1014787,15z/data=!4m5!3m4!1s0x0:0xd042c920e2be6ed2!8m2!3d4.6366685!4d101.101476">
                    FIND US ON GOOGLE MAP
                  </a>
                </div>
              </div>
            )}

            <div style={{ marginTop: 16 }}>
              <button type="button" className="action-button" onClick={modalShow}>WRITE TO US</button>
            </div>
          </div>
        </div>
        {/* <div className="contact-us">
          <CalrockForm />
        </div> */}
        <div>
          <Footer />
        </div>
      </main>
      <Modal
        show={show}
        onHide={modalClose}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <CalrockForm modalClose={modalClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
