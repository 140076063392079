import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import { useUtilState } from '../provider/UtilProvider';
import './Carousel.scss';

import plastics from '../img/our-products/plastics.jpg';
import rubber from '../img/our-products/rubber.jpg';
import paintsAndCoating from '../img/our-products/paints-and-coating.jpg';
import others1 from '../img/our-products/others1.jpg';
import others2 from '../img/our-products/others2.jpg';

export default function ApplicationAccordion() {
  const { isSmallScreen } = useUtilState();

  return (
    <div>
      {!isSmallScreen && (
        <div>
          <h2 className="title-color">APPLICATIONS</h2>
          <Accordion alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>PLASTICS</Accordion.Header>
              <Accordion.Body>
                <div style={{ flex: 1, fontSize: 24 }}>
                  Calcium Carbonate improves impact strength in plastic products while providing
                  high-gloss, smooth finish and whiteness.
                </div>
                <div style={{ flex: 1, marginRight: 32 }}>
                  <div />
                  <img
                    src={plastics}
                    style={{
                      width: 'auto', height: '250px', display: 'block', marginLeft: 'auto',
                    }}
                    alt="plastics"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>RUBBER</Accordion.Header>
              <Accordion.Body>
                <div style={{ flex: 1, fontSize: 24 }}>
                  The physical characteristics of rubber, such as resistance and
                  strength is enhanced with Calcium Carbonate.
                </div>
                <div style={{ flex: 1, marginRight: 32 }}>
                  <img
                    src={rubber}
                    style={{
                      width: 'auto', height: '250px', display: 'block', marginLeft: 'auto',
                    }}
                    alt="rubber"
                  />
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>PAINTS & COATINGS</Accordion.Header>
              <Accordion.Body>
                <div style={{ flex: 1, fontSize: 24 }}>
                  Pigments used by paints and coatings industry comprises Calcium Carbonate
                  powder for colour control and cost reduction.
                </div>
                <div style={{ flex: 1, marginRight: 32 }}>
                  <img
                    src={paintsAndCoating}
                    style={{
                      width: 'auto', height: '250px', display: 'block', marginLeft: 'auto',
                    }}
                    alt="paint-and-coating"
                  />
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>OTHERS</Accordion.Header>
              <Accordion.Body>
                <div style={{ flex: 1, fontSize: 24 }}>
                  Calcium Carbonate powder is widely used in various industries
                  due to its versatile components.
                </div>
                <div style={{ flex: 1, marginRight: 32 }}>
                  <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <img
                      src={others1}
                      style={{ width: 'auto', height: '250px' }}
                      alt="others1"
                    />
                    <img
                      src={others2}
                      style={{ width: 'auto', height: '250px', display: 'block' }}
                      alt="others2"
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      {isSmallScreen}
      {isSmallScreen && (
        <Carousel controls={false}>
          <Carousel.Item
            className="application-carousel"
            activeIndex={1}
            interval={3000}
          >
            <Carousel.Caption className="caption-header" style={{ opacity: '1' }}>
              <h3 style={{ padding: '1rem 0rem' }}>APPLICATIONS</h3>
              <div className="caption-image-cover">
                <img
                  src={plastics}
                  className="caption-img"
                  alt="plastics"
                />
              </div>
              <div className="caption-title">
                <span>
                  PLASTIC
                </span>
              </div>
              <div className="caption-content">
                <span>
                  Calcium Carbonate improves impact strength in plastic products while providing
                  high-gloss, smooth finish and whiteness.
                </span>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item
            className="application-carousel"
            activeIndex={1}
            interval={3000}
          >
            <Carousel.Caption className="caption-header" style={{ opacity: '1' }}>
              <h3 style={{ padding: '1rem 0rem' }}>APPLICATIONS</h3>
              <div className="caption-image-cover">
                <img
                  src={rubber}
                  className="caption-img"
                  alt="plastics"
                />
              </div>
              <div className="caption-title">
                <span>RUBBER</span>
              </div>
              <div className="caption-content">
                <span>
                  The physical characteristics of rubber, such as resistance and
                  strength is enhanced with Calcium Carbonate.
                </span>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item
            className="application-carousel"
            activeIndex={1}
            interval={3000}
          >
            <Carousel.Caption className="caption-header" style={{ opacity: '1' }}>
              <h3 style={{ padding: '1rem 0rem' }}>APPLICATIONS</h3>
              <div className="caption-image-cover">
                <img
                  src={paintsAndCoating}
                  className="caption-img"
                  alt="plastics"
                />
              </div>
              <div className="caption-title">
                <span>
                  PAINTS & COATING
                </span>
              </div>
              <div className="caption-content">
                <span>
                  Pigments used by paints and coatings industry comprises Calcium Carbonate
                  powder for colour control and cost reduction.
                </span>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item
            className="application-carousel"
            activeIndex={1}
            interval={3000}
          >
            <Carousel.Caption className="caption-header" style={{ opacity: '1' }}>
              <h3 style={{ padding: '1rem 0rem' }}>APPLICATIONS</h3>
              <div className="caption-image-cover">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={others1}
                    className="caption-img-2"
                    alt="plastics"
                  />
                  <img
                    src={others2}
                    className="caption-img-2"
                    alt="plastics"
                  />
                </div>
              </div>
              <div className="caption-title">
                <span>OTHERS</span>
              </div>
              <div className="caption-content">
                <span>
                  Calcium Carbonate powder is widely used in various industries
                  due to its versatile components.
                </span>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      )}

    </div>
  );
}
