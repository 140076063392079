import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import coreValue1 from '../img/our-company/cv_1.jpg';
import coreValue2 from '../img/our-company/cv_2.jpg';
import coreValue3 from '../img/our-company/cv_3.jpg';
import coreValue4 from '../img/our-company/cv_4.jpg';
import { useUtilState } from '../provider/UtilProvider';

export default function CoreValuesCarouselComponent() {
  const { isSmallScreen } = useUtilState();

  return (
    <Carousel controls={false}>
      <Carousel.Item>
        <Carousel.Caption className="caption-header">
          <h3>OUR CORE VALUES</h3>
        </Carousel.Caption>
        <div
          className="d-block w-100"
          style={{
            height: 500,
            background: `url(${coreValue1}) no-repeat ${!isSmallScreen ? 'center / cover' : 'left -150px center /cover'}`,
            color: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '50%',
              height: 'inherit',
              margin: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: isSmallScreen ? '2.2rem' : '2.7em', textAlign: isSmallScreen ? 'center' : 'left', fontWeight: '600' }}>Integrity, honesty and loyalty</span>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <Carousel.Caption className="caption-header">
          <h3>OUR CORE VALUES</h3>
        </Carousel.Caption>
        <div
          className="d-block w-100"
          style={{
            height: 500,
            background: `url(${coreValue2}) no-repeat ${!isSmallScreen ? 'center / cover' : 'right -200px center /cover'}`,
            color: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '50%',
              height: 'inherit',
              margin: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: isSmallScreen ? '2.2rem' : '2.7em', textAlign: isSmallScreen ? 'center' : 'left', fontWeight: '600' }}>Improving the quality of life of our employees and customers</span>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>

        <Carousel.Caption className="caption-header">
          <h3>OUR CORE VALUES</h3>
        </Carousel.Caption>

        <div
          className="d-block w-100"
          style={{
            width: '60%',
            height: 500,
            background: `url(${coreValue3}) no-repeat center / cover`,
            color: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '50%',
              height: 'inherit',
              margin: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: isSmallScreen ? '2.2rem' : '2.7em', textAlign: isSmallScreen ? 'center' : 'left', fontWeight: '600' }}>
              Protecting our planet and natural resources
              by enforcing sustainable development practices

            </span>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <Carousel.Caption className="caption-header">
          <h3>OUR CORE VALUES</h3>
        </Carousel.Caption>

        <div
          className="d-block w-100"
          style={{
            height: 500,
            background: `url(${coreValue4}) no-repeat ${!isSmallScreen ? 'center / cover' : 'right -340px center /cover'}`,
            color: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '50%',
              height: 'inherit',
              margin: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: isSmallScreen ? '2.2rem' : '2.7em', textAlign: isSmallScreen ? 'center' : 'left', fontWeight: '600' }}>To create cost-saving solutions for our customers</span>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}
